import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import './theme/element/index.css'
Vue.use(ElementUI);

import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)

//css
import "./assets/css/public.css";
import "./assets/css/component.css";
import "./assets/css/style.css";
// 提示框
import vueSweetalert2 from '@/plugins/sweetalert2'
Vue.use(vueSweetalert2)
Vue.prototype.$Bus = new Vue();
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

/**
 * 注册、登录、注销
 * */
import util from '@/libs/util'
import router from '@/router'
import userApi from '@/api/user'
import swal from 'sweetalert2'

// import { Modal } from 'view-design';

export default {
    namespaced: true,
    actions: {
        /**
         * @description 登录
         * @param {Object} param context
         * @param {Object} param username {String} 用户账号
         * @param {Object} param password {String} 密码
         * @param {Object} param route {Object} 登录成功后定向的路由对象 任何 vue-router 支持的格式
         */
        login({dispatch}, {username = '', captcha = ''} = {}) {
            return new Promise((resolve, reject) => {
                userApi.login({
                        phone: username,
                        captcha: captcha
                    })
                    .then(async (res) => {
                        // 设置 cookie 一定要存 uuid 和 token 两个 cookie
                        // 整个系统依赖这两个数据进行校验和存储
                        // uuid 是用户身份唯一标识 用户注册的时候确定 并且不可改变 不可重复
                        // token 代表用户当前登录状态 建议在网络请求中携带 token
                        // 如有必要 token 需要定时更新，默认保存一天，可在 setting.js 中修改
                        // 如果你的 token 不是通过 cookie 携带，而是普通字段，也可视情况存储在 localStorage
                        // console.log(res)
                        util.cookies.set('uuid', res.user.id)
                        util.cookies.set('token', res.token)
                        // 设置 vuex 用户信息
                        await dispatch('admin/user/set', res.user, {root: true})
                        // 用户登录后从持久化数据加载一系列的设置
                        await dispatch('load')
                        // 结束
                        resolve()
                    })
                    .catch((err) => {
                        // console.log('err: ', err);
                        reject(err)
                    })
            })
        },
        /**
         * @description 退出登录
         * */
        /**
         * @description 退出登录
         * */
        logout({ commit, dispatch }, { confirm = false, vm } = {}) {
            async function logout() {
                // 删除cookie
                util.cookies.remove('token');
                util.cookies.remove('uuid');
                // 清空 vuex 用户信息
                await dispatch('admin/user/set', {}, { root: true });
                // 跳转路由
                router.push({
                    path: '/login'
                });
            }

            swal.fire({
                title: '确定要退出吗？\r\nAre you sure to logout?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: '确定 OK',
                confirmButtonColor: '#2d8cf0',
                cancelButtonText: '取消 Cancel'
            }).then(result => {
                if (result.value) {
                    logout();
                }
            });
        },
        /**
         * @description 用户登录后从持久化数据加载一系列的设置
         * @param {Object} state vuex state
         * @param {Object} dispatch vuex dispatch
         */
        load({state, dispatch}) {
            return new Promise(async (resolve) => {
                // 加载用户登录信息
                await dispatch('admin/user/load', null, {root: true})
                // 持久化数据加载上次退出时的多页列表
                // await dispatch('admin/page/openedLoad', null, { root: true })
                // end
                resolve()
            })
        },
    },
}

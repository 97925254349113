// 导入自有语言包
import zhCN from './locale/zh-CN';

// 导入布局语言包
import layoutLocale from '@/layouts/i18n';

// 合并语言包
export default {
    'zh-CN': Object.assign(zhCN, layoutLocale['zh-CN'])
};

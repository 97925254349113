import request from '@/plugins/request'

const res = 'customer'

export default {
    login(params) {
        return request({
            url: `customer/login`,
            method: 'post',
            data: params,
        })
    },
    loginOut(params) {
        return request({
            url: `customer/${res}/loginOut`,
            method: 'get',
            params,
        })
    },
    index(params) {
        return request({
            url: `customer/${res}`,
            method: 'get',
            params
        })
    },
}

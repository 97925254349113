import sweetalert2 from 'sweetalert2'

export default {
  install: (Vue) => {
    // sweetalert2 的设置默认配置的方法
    const swal = sweetalert2.mixin({
      confirmButtonText: '确定',
      confirmButtonColor: '#2d8cf0',
      cancelButtonText: '取消',
    })

    // 添加全局方法
    Vue.swal = swal

    // 添加实例方法
    Vue.prototype.$swal = swal
  },
}

import util from '@/libs/util'
import {mapState, mapActions} from 'vuex'
import setting from '@/setting'
import dateFormat from 'date-fns/format'
import Api from '@/api'

// 格式化数字
function formatDecimal(number, bitNum) {
    if (isNaN(number)) {
        return ''
    }
    var str = number.toString()
    var pos = str.indexOf('.')
    if (pos < 0) {
        pos = str.length
        str += '.'
    }
    while (str.length <= pos + bitNum) {
        str += '0'
    }
    return str
}

/**
 * 页面公共混合
 * */
export default {
    data() {
        return {
            grid4: {
                xxl: 4,
                xl: 4,
                lg: 4,
                md: 6,
                sm: 12,
                xs: 24,
            },
            grid6: {
                xxl: 6,
                xl: 6,
                lg: 8,
                md: 12,
                sm: 12,
                xs: 24,
            },
            grid8: {
                xxl: 8,
                xl: 8,
                lg: 8,
                md: 12,
                sm: 24,
                xs: 24,
            },
            grid12: {
                xxl: 12,
                xl: 12,
                lg: 12,
                md: 12,
                sm: 24,
                xs: 24,
            },
            grid16: {
                xxl: 16,
                xl: 16,
                lg: 16,
                md: 24,
                sm: 24,
                xs: 24,
            },
            grid24: {
                xxl: 24,
                xl: 24,
                lg: 24,
                md: 24,
                sm: 24,
                xs: 24,
            },
        }
    },
    computed: {
        ...mapState('admin/user', ['info']),
        ...mapState('admin/permission', ['userPermissions']),
        uploadURL() {
            return setting.apiBaseURL + '/api/common/upload'
        },
        uploadHeaders() {
            const token = util.cookies.get('token')
            return {
                Authorization: 'Bearer ' + token,
            }
        },
        apiBaseURL(){
            return setting.apiBaseURL
        }
    },
    methods: {
        ...mapActions('admin/cache', [
            'loadEnum',
            'loadDict',
            'loadUserName',
            'loadCache',
        ]),
        //获取项目列表
        async getProjectList() {
            let params = {
                limit: 'all'
            }
            return await Api.model.index('project',params).then(res=>{
                let project = []
                res.forEach((item)=>{
                    project.push({
                        label:item.name,
                        value:item.id
                    })
                })
                return project
            })
        },


        resetForm(form) {
            this.$nextTick(() => {
                this.$refs[form].resetFields()
            })
        },
        copy(model) {
            return JSON.parse(JSON.stringify(model))
        },
        allow(key) {
            this.$store.dispatch('admin/permission/load')
            return this.userPermissions[key]
        },
        getCache(type, id) {
            this.loadCache(type)
            return this.caches[type].find((x) => x.id == id)
        },
        getCacheList(type) {
            this.loadCache(type)
            return this.caches[type]
        },
        getEnum(type, id) {
            this.loadEnum(type)
            return this.enums[type][id]
        },
        getEnumList(type) {
            this.loadEnum(type)
            return Object.keys(this.enums[type]).map((key) => {
                return {
                    id: key,
                    name: this.enums[type][key],
                }
            })
        },
        getDict(type, id) {
            this.loadDict(type)
            return this.dicts[type][id]
        },
        getDictList(type) {
            this.loadDict(type)
            return Object.keys(this.dicts[type]).map((key) => {
                return {
                    id: key,
                    name: this.dicts[type][key],
                }
            })
        },
        getUserName(id) {
            this.loadUserName(id)
            return this.userNames[id]
        },
        // 附件
        getAttachmentUrl(file) {
            if (file) {
                return setting.apiBaseURL + 'api/Attachment/GetAttachment?file=' + file
            } else {
                return null
            }
        },
        // 图片路径
        getImgUrl(file) {
            if (file) {
                return setting.apiBaseURL + '' + file
            } else {
                return '@/assets/images/no-image.png'
            }
        },
        // 显示格式化内容
        showData(model, expression, dataType, format) {
            if (dataType.indexOf('Enum:') === 0) {
                return this.getEnum(
                    dataType.replace('Enum:', ''),
                    this.parseData(model, expression)
                )
            } else if (dataType === 'UUID') {
                return this.getUserName(this.parseData(model, expression))
            }

            let value = this.parseData(model, expression)

            if (value == null || value === '') {
                return null
            }

            if (dataType === 'DateTime') {
                // 日期
                if (format != null && format !== '') {
                    return dateFormat(new Date(value), format)
                    // return $filter('date')(value, format);
                } else {
                    return dateFormat(new Date(value), 'yyyy-MM-dd')
                    // return $filter('date')(value, 'yyyy-MM-dd');
                }
            } else if (dataType === 'Decimal') {
                // 小数
                if (format === 'Money') {
                    return formatDecimal(value, 2)
                } else {
                    return value.toString()
                }
            } else if (dataType === 'Boolean') {
                if (format === 'Y') {
                    return value ? '<i class="fa fa-check info"></i>' : ''
                } else if (format === 'YN') {
                    return value
                        ? '<i class="fa fa-check"></i>'
                        : '<i class="fa fa-times"></i>'
                } else if (format === 'Disabled') {
                    return value ? '<i class="fa fa-ban error"></i>' : ''
                } else if (format === 'Valid') {
                    return value ? '<i class="fa fa-check success"></i>' : ''
                } else if (format === 'Attention') {
                    return value ? '<i class="fa fa-exclamation error"></i>' : ''
                } else {
                    return value ? '<i class="fa fa-check info"></i>' : ''
                }
            } else if (dataType === 'String') {
                if (format === 'User') {
                    if (value && value.length > 1) {
                        value = value.slice(0, 1) + '**'
                    }
                }
                return value
            } else {
                return value
            }
        },
        // 根据表达式取值
        parseData(model, expression) {
            if (expression == null) {
                return null
            }

            let keys = expression.split('.')
            let result = model

            for (let i = 0; i < keys.length; i++) {
                if (result == null) {
                    return null
                }
                result = result[keys[i]]
            }

            return result
        },
        // 根据表达式赋值
        setData(model, expression, value) {
            //console.log('setData', model, expression, value);
            if (expression == null) {
                return
            }

            let keys = expression.split('.')
            let target = model

            for (let i = 0; i < keys.length; i++) {
                if (i === keys.length - 1) {
                    target[keys[i]] = value
                } else {
                    if (!(keys[i] in target)) {
                        target[keys[i]] = {}
                    }

                    target = target[keys[i]]
                }
            }

            this.$forceUpdate()
        },
        // 确认框
        confirm(title, onConfirm) {
            let swal = this.$swal.fire({
                title: title,
                icon: 'question',
                showCancelButton: true,
            })

            swal.then((result) => {
                if (result.value) {
                    if (typeof onConfirm === 'function') {
                        onConfirm()
                    }
                }
            })

            return swal
        },
        // 成功提示
        success(message, onConfirm) {
            var swal = this.$swal.fire({
                title: message,
                icon: 'success',
            })

            swal.then((result) => {
                if (typeof onConfirm === 'function') {
                    onConfirm()
                }
            })

            return swal
        },
        // 警告提示
        warning(message, onConfirm) {
            var swal = this.$swal.fire({
                title: message,
                icon: 'warning',
            })

            swal.then((result) => {
                if (typeof onConfirm === 'function') {
                    onConfirm()
                }
            })

            return swal
        },
        // 失败提示
        error(message, onConfirm) {
            var swal = this.$swal.fire({
                title: message,
                icon: 'error',
            })

            swal.then((result) => {
                if (typeof onConfirm === 'function') {
                    onConfirm()
                }
            })

            return swal
        },
        //下载文件
        download(res,fileName) {
            const blob = new Blob([res]); // 创建blob对象
            const elink = document.createElement("a"); // 创建的标签
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob); // 创建url
            document.body.appendChild(elink); // 把 创建的标签追加到body里
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink); // 移除标签
            this.success('导出成功！！！');
        },
    },
}

<template>
    <el-header class="top_bar">
        <div class="container flex">
            <div class="top_bar_left">
                <div class="logo-img">
                    <router-link to="/">
                        <img src="../../assets/images/logo.png" alt=""/>
                    </router-link>
                </div>
            </div>
            <div class="top_bar_right flex">
                <el-dropdown>
                    <div class="el-dropdown-link">
                        <div class="avatar flex">
                            <!--<span class="avatar_img">-->
                            <!--<img src="../../assets/images/avatar.jpg" alt />-->
                            <!--</span>-->
                            <span class="avatar_name">{{ info.contact_people_name }}</span>
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </div>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="loginout" @click.native="loginout()">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </el-header>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import mixinPage from '@/mixins/page'
    export default {
        mixins: [mixinPage],
        data() {
            return {};
        },
        created() {
            this.load()
        },
        methods: {
            ...mapActions('admin/user', {
                load: 'load',
            }),
            ...mapActions('admin/account', {
                loginOutStore: 'logout',
            }),
            loginout() {
                this.loginOutStore({
                    confirm: this.logoutConfirm,
                    vm: this
                });
            },
        }
    };
</script>

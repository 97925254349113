import util from '@/libs/util'

export default{
    namespaced:true,
    state:{
        userPermissions:null
    },
    getters:{

    },
    actions:{
        async load({state},key){
            if(state.userPermissions==null){
                state.userPermissions = {}
                state.userPermissions = await util.db.get('permission').value()
            }
        },
        async set({state},data){

            util.db.set('permission',data).write()
            state.userPermissions = data
        }
    }


}

import cookies from './util.cookies'
import log from './util.log'
import db from './util.db'

import Setting from '@/setting'

const util = {
  cookies,
  log,
  db,
}

function tTitle(title = '') {
  if (window && window.$t) {
    if (title.indexOf('$t:') === 0) {
      return window.$t(title.split('$t:')[1])
    } else {
      return title
    }
  } else {
    return title
  }
}

export default util

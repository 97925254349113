//默认布局使用中文

export default {
  'zh-CN': {
    login: {
      login: {
        name: '登錄',
      },
      ResetPassword: {},
    },
    main: {
      index: {},
      my_manuscript: {},
      new_manuscript: {},
    },
  },
}

<template>
  <div>
    <el-container v-if="$route.meta.keepAlive">
      <Header />
      <el-main>
        <router-view />
      </el-main>
    </el-container>
    <router-view v-else />
  </div>
</template>

<script>
import Header from "./components/public/header";
export default {
  data() {
    return {};
  },
  components: {
    Header
  },
  methods: {}
};
</script>

import request from '@/plugins/request';


export default {
    //添加数据
    store(res, data) {
        return request({
            url: `customer/${res}`,
            method: 'post',
            data: data
        });
    },
    //修改数据
    update(res, data, id) {
        return request({
            url: `customer/${res}/` + id,
            method: 'put',
            data: data
        });
    },
    //删除数据
    destroy(res, id) {
        return request({
            url: `customer/${res}/` + id,
            method: 'delete'
        });
    },
    //获取数据详情
    show(res, id) {
        return request({
            url: `customer/${res}/` + id,
            method: 'get'
        });
    },
    //获取列表
    index(res, params,responseType) {
        return request({
            url: `customer/${res}`,
            method: 'get',
            responseType:responseType,
            params,
        })
    },
    //自定义请求
    custom(url,method,data){
        return request({
            url: url,
            method: method,
            data: data
        });
    }

}

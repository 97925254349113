import Vue from "vue";
import VueRouter from "vue-router";
import util from '@/libs/util'
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    meta: { title: process.env.VUE_APP_TITLE + " - 登录", keepAlive: false },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login")
  },{
    path: "/",
    name: "Index",
    meta: { title: process.env.VUE_APP_TITLE + " - 首页", keepAlive: true },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/index")
  },{
    path: "/index",
    name: "IIndex",
    meta: { title: process.env.VUE_APP_TITLE + " - 首页", keepAlive: true },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/index")
  },{
    path: "/program/:id",
    name: "ProgramView",
    meta: { title: process.env.VUE_APP_TITLE + " - 项目详情", keepAlive: true },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/programView")
  },{
    path: "/task/:id",
    name: "TaskView",
    meta: { title: process.env.VUE_APP_TITLE + " - 任务详情", keepAlive: true },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/taskView")
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
    // 判断是否需要登录才可以进入
    if (to.matched[0].meta.keepAlive) {
        // 这里依据 token 判断是否登录，可视情况修改
        const token = util.cookies.get('token');
        if (token && token !== 'undefined') {
            next();
        } else {
            // 没有登录的时候跳转到登录界面
            // 携带上登陆成功之后需要跳转的页面完整路径
            next({
                name: 'Login',
                query: {
                    redirect: to.fullPath
                }
            });
        }
    } else {
        // 不需要身份校验 直接通过
        next();
    }
})


export default router;

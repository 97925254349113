/**
 * 遮罩层
 * */

export default {
  namespaced: true,
  state: {
    count: 0, // 计数
    isLoading: false, // 加载中
  },
  mutations: {
    /**
     * @description 开始加载
     * @param {Object} state vuex state
     */
    start(state) {
      state.count++
      state.isLoading = true
    },
    /**
     * @description 加载结束
     * @param {Object} state vuex state
     * */
    end(state) {
      state.count--
      state.isLoading = state.count > 0
    },
  },
}
